.simple-select {
    width: 100%;
    border-radius: 4px;

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    &.ant-select .ant-select-selector {
        font-size: 14;
        border-radius: 0px;
        align-items: center;
        justify-content: center;
        height: 50px !important;
        box-shadow: none !important;
        border: 1px solid #DDD;
        background: #ffffff;

        .ant-select-selection-placeholder {
            font-size: 14;
            color: rgb(180, 180, 180);
        }
    }

    &.select-error .ant-select-selector {
        border: 1px solid #CCC !important;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #FFF !important;
    background-color: var(--primary) !important;
}