@use "/src/common/styles/colors" as colors;

.home-page {
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-direction: column;
    background-color: #FFF;
    min-height: calc(100vh - 47px);

    .form-side {
        width: 100%;
        margin: 90px 0 auto;

        max-width: 475px;
        text-align: left;
        box-sizing: border-box;
        padding: 0 14px 54px;

        .form-title {
            font-size: 18px;
            font-weight: 700;
        }

        .form-subtitle {
            font-size: 12px;
            font-weight: 400;
        }

        .case-info {
            font-size: 12px;
            font-weight: 400;
            
            h3 {
                font-size: 16px;
                font-weight: bold;
                margin-top: 25px;
            }
        }

        .case-not-found {
            font-size: 16px;
            font-weight: bold;
            margin-top: 25px;
            color: colors.$red-0;
        }

        .ant-form {
            width: 100%;
            padding-top: 11px;
            margin: 16px auto 0;

            .ant-form-item-explain {
                font-size: 12px;
            }

           .ant-form-item {
                margin: 0 0 16px;

                .ant-form-item-label>label {
                    font-size: 12px;
                }

                .ant-form-item-label {
                    padding: 0;

                    label::before {
                        display: none;
                    }

                    .ant-form-item-required {
                        .label-tooltip {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .anticon {
                                margin-left: 6px;
                            }
                        }
                    }
                }


                .simple-input,
                .ant-select {
                    margin: 0;
                    width: 100%;
                    font-size: 14px;

                    .ant-input-prefix {
                        margin-right: 10px;
                    }

                    &::placeholder {
                        font-size: 14px;
                    }
                }

                &.ant-form-item-control-select {
                    margin-bottom: 16px;

                    .rounded-select {
                        .ant-select-selector {
                            padding: 0;
                            width: 100%;
                            height: 40px;
                            text-indent: 10px;
                            font-weight: normal;
                            box-sizing: border-box;
                            border-radius: 4px !important;
                            border: 1px solid #525252 !important;

                            .ant-select-selection-placeholder {
                                display: flex;
                                font-size: 14px;
                                align-items: center;
                                // color: colors.$gray-2;
                            }

                            .ant-select-selection-search-input {
                                font-size: 16px;
                            }
                        }
                    }

                    .ant-form-item-explain {
                        top: calc(100% - 16px);
                    }
                }

                &.ant-form-item-has-error {

                    .simple-input,
                    .rounded-select .ant-select-selector {
                        border: 1px solid red !important;
                    }
                }
            }

            .simple-button {
                float: right;
                margin-top: 12px;
            }
        }
    }

    .anticon-question-circle {
        margin-left: 6px;

        svg {
            border-radius: 50%;
            fill: var(--primary);
        }
    }
}

@media screen and (min-width: 768px) {
    .home-page {
        min-height: calc(100vh - 50px);
        .form-side {
            max-width: 658px;
            padding: 0 42px 42px;
            margin: 20px auto 0;

            .step-container {
                margin: 0 auto 46px;

                .step-title {
                    margin: 30px 0;
                    font-size: 18px;
                    text-align: center;
                }
            }

            .form-title {
                font-size: 25px;
            }

            .form-subtitle {
                font-size: 14px;
            }

            .form-observation {
                font-size: 12px;
            }

            .ant-form {
                padding-top: 24px;
                margin: 29px auto 0;

                .junction-inputs {
                    display: grid;
                    grid-gap: 16px;
                    grid-template-columns: 1fr 1fr;
                }

                .ant-form-item {
                    .ant-form-item-margin-offset {
                        margin-bottom: 0 !important;
                    }

                    .ant-form-item-label>label {
                        font-size: 14px;
                        padding-bottom: 4px;
                    }

                    .simple-input,
                    .ant-select {
                        font-size: 15px;

                        .ant-input-prefix {
                            margin-right: 10px;
                        }

                        &::placeholder {
                            font-size: 14px;
                        }
                    }
                }

                .container-select .ant-select {
                    margin-top: 12px;

                    .ant-select-selector {
                        height: 50px;
                    }
                }

                .simple-button {
                    grid-column: 2;
                    max-width: 150px;
                    margin: 0 0 0 auto;
                }
            }
        }
    }
}

@media screen and (min-width: 1024px) {
    .home-page {
        min-height: calc(100vh - 123px);

        .form-side {
            margin: 90px 0 auto;
            max-width: none;
            padding: 60px 81px;
            flex-wrap: nowrap;

            .form-title {
                font-size: 32px;
                font-weight: 500;
                margin-bottom: 8px;
            }

            .step-about {
                .form-subtitle {
                    font-size: 16px;
                    font-weight: 400;
                    margin-bottom: 8px;
                }

                .form-observation {
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .right-side {
                .form-list {
                    border: none;
                    margin-top: 0;

                    .form-list-title {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1440px) {
    .home-page {
        min-height: calc(100vh - 90px);

        .form-side {
            max-width: 1440px;
            padding: 60px 105px;
        }
    }
}

.terms-use {
    padding: 100px

}