.card-payment-result {
    .card-success {

    }

    .card-error {
        svg {
            color: rgb(255, 187, 0) !important;
        }
    }
}