.login-container {
    width: 100%;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: flex;

    .login-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
        background-color: #007AFF;
        box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

        .carrossel {
            width: 100%;
            max-width: 400px;

            .slick-dots-bottom {
                position: relative;
                bottom: 0px;
            }

            .ant-col.carrossel-item {
                padding: 15px;

                > div {
                    padding: 15px;
                    background-color: rgba(243, 243, 243, 0.5);
                    border-radius: 8px;
                    height: 426px;
                    // width: 400px;
                    
                    .img-container {
                        height: 200px;

                        img {
                            padding-top: 30px;
                            margin: 0 auto;
                        }

                        img.img-horizontal {
                            width: 230px;
                        }

                        img.img-vertical {
                            width: 150px;
                        }
                    }

                    .img-text {
                        height: 180px;

                        p {
                            height: 100px;
                            text-align: center;
                            font-size: 1.1em;
                        }
                    }
                }
            }
        }
    }

    .error-message-login {
        width: 100%;
        text-align: center;
        color: rgb(255, 44, 44);
        padding-bottom: 2em 0;
    }

    .login-form {
        padding: 7em 10em;
        width: 50%;
        height: 100%;
        margin: 0 auto;

        .auth-page-name {
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            font-size: 32px;
            margin-top: 80px;
            margin-bottom: 110px;
        }

        .login-form-logo {
            width: 100%;
            text-align: center;

            img {
                max-width: 40%;
                padding-bottom: 2em;
            }
        }

        .form-login {
            max-width: 400px;
            margin: 0 auto;
        }

        .link-esqueci-minha-senha {
            width: 100%;
            text-align: right;
            text-decoration-line: underline;
            text-decoration-color: #007AFF;
        }

        .login-form-button-pagina-login {
            width: 100%;
            padding-top: 3em;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
        }

        .login-new-account-pagina-login {
            // margin-top: 125px;
            text-align: center;

            a {
                // padding-left: 5px;
                font-weight: 600;
            }
        }
    }

    // .ant-btn-default:disabled {
    //     background-color: var(--primary);
    //     color: #fff;
    // }
}



@media screen and (max-width: 1200px) {

    .slick-slide {
        width: 100% !important;
        max-width: 400px;
    }

    .login-logo {
        display: none !important;
    }

    .login-form {
        width: 100% !important;
        max-width: 600px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1366px) {

    .slick-slide {
        width: 100% !important;
        max-width: 400px;
    }

    .login-form {
        padding: 5em 2em !important;
        width: 100% !important;
        max-width: 600px;
        margin: 0 auto;
    }
}