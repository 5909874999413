.container-files-attachment {

    .not-overflow {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display:inline-block;
    }

    .file-infos {
      margin-bottom: 8px;
    }

    .file-type, .file-created-at {
      font-size: 12px;
      font-weight: bold;
      color: rgb(131, 131, 131);
      text-transform: uppercase;
    }

    .file-status {
        .anticon svg {
            width: 32px;
            height: 32px;
        }
    }

    .file-created-at {
      font-weight: normal;
    }

    .filename {
      font-size: 16px;
      font-weight: normal;
    }
    
  }
