.row-context-options {

    .question-icon {
        margin-left: 5px;
    }
    
    .group-context-options {
        margin: 10px 0;
        
        * {
            font-size: 12px !important;
            text-transform: capitalize !important;
        }
    }
}