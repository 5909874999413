@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.ant-tabs-nav {
    margin: 0px !important;
}

.target-view-page {
    padding-bottom: 60px;

    .loading-text {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        color: blue;
    }

    @media (max-width: $md-screen) {
        .ant-tabs-nav {
            display: none;
        }
    }

    .hide-on-full-screen {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }

    .ant-table-cell, .ant-table-cell div.hide-on-full-screen {
      > div:not(:last-child), p:not(:last-child)  {
        margin-bottom: 0 !important;
        
        @media screen and (max-width: $md-screen) {
          margin-bottom: 5px !important;
        }
      }

      @media screen and (max-width: $md-screen) {
        label {
          font-weight: 600 !important;
        }
      }
    }

    .label-select {
        margin-bottom: 8px;
    }

    
    .select-section {
        margin-bottom: 30px;

        &.ant-select {
            width: 100%;
            max-width: 700px;
            
            .ant-select-selector {
              border-radius: 0 !important;
              
              .ant-select-selection-search input {
                border-radius: 0 !important;
              }
            }

            .ant-select-selector, .ant-select-selection-item {
              height: 40px;
            }
    
            @media (max-width: $md-screen) {
              max-width: none;
            }
    
            .ant-select-selection-item {
              @extend .font-normal-14-600;
              color: colors.$black-2;
              position: relative;
              top: 5px;
            }
    
            .ant-select-selection-placeholder {
              @extend .font-normal-14-600;
              color: colors.$blue-1;
              position: relative;
              top: 5px;
            }
          }
    }
}