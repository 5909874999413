.payment-modal {

  .payment-billet {
    width: 100%;
    background-color: #eee;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      font-weight: 600;
      color: #007AFF;
      font-size: 14px;
    }

    svg {
      margin-left: 5px;
      width: 15px;
    }
  }
  .payment-methods {
    text-align: center !important;

    .ant-btn, button {
      width: 100% !important;
      min-width: 110px !important;
      max-width: 195px !important;
    }

    @media screen and (max-width: 768px) {
      .ant-col {
        width: 100% !important;
      }
    }
  }

  .payment-qr-code {
    border: 1px solid #dddddd;
    background-color: #e4e4e4;
    color: black;
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;

    :first-child {
      width: 100%;
      overflow: hidden;
    }

    :nth-child(2) {
      display: flex;
      align-items: center;
      margin-left: 15px;
    }

    svg {
      height: 20px;
      cursor: pointer;
    }
  }

  .image-qrcode {
    margin-bottom: 25px;
  }

  .download-billet {
    .download-icon {
      margin-right: 15px;
      margin-top: 2px;
    }
  }

  .item-purchase-row {
    .icon-action {
        color: black;
    }

    .icon-action:hover {
      color: var(--primary);
      cursor: pointer;
    }
  }
}
