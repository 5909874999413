@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.resumo-processos {
    table {
        margin-bottom: 24px;
        thead {
            border-radius: 8px;
            background-color: #f2f2f2;
        }

        td, th {
            padding: 5px;
            border-right: 1px solid #f2f2f2;
            &:first-child{
                border-left: 1px solid #f2f2f2;
            }

            border-bottom: 1px solid #f2f2f2;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

    }
}