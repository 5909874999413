.access-container {
    margin: 0 auto;
    // width: 90%;
    // max-width: 1000px;
    padding: 0;
    display: flex;
    justify-content: flex-start;

    .form-subtitle {
        font-size: 14px;
        font-weight: normal;
        margin-bottom: 8px;
        max-width: 50%;
    }

    .form-observation {
        font-size: 14px;
        font-weight: 400;
    }

    .auth-page-name {
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 32px;
        // margin-top: 52px;
        margin-bottom: 37px;
    }

    .info-box {
        text-align: center;
        // padding-top: 7em;
        width: 100%;

        img {
            width: 200px;
            height: auto;
            margin-bottom: 50px;
            margin-top: 20px;
        }
    }

    .new-account-form {
        padding-top: 2em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 50px;

        .ant-form {
            max-width: 495px;
        }
    }

    .card-termos {
        margin: 10px 0;
    }

    .ant-btn-default:disabled {
        background-color: var(--primary);
        color: #fff;
    }

    .ant-btn-lg {
        border-radius: 50px;
    }

    .steps-register {
        padding: 0 10%;
        display: flex;
        justify-content: center;
        position: relative;
        left: -4%;
        margin-bottom: 42px;

        .ant-steps {
            max-width: 400px;

            .ant-steps-item-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .register-left {
        min-height: 100vh;
        width: 50vw;
        background-color: #007aff;
        padding-bottom: 60px;
    }

    .register-rigth {
        display: flex;
        width: 50vw;
        justify-content: center;
        align-self: center;
        flex-direction: column;
        padding-bottom: 60px;
    }
}

@media screen and (max-width: 768px) {
    .access-container {
        .ant-row {
            display: inline;
            width: 100% !important;
        }

        .new-account-form {
            padding: 2em;
        }

        .register-left {
            display: none;
        }

        .register-rigth {
            width: 100vw;
        }

        .new-account-form {
            padding: 0 10px;

            .ant-form {
                width: 100%;
            }
        }

        .steps-register {
            position: relative;
            left: 0;

            .ant-steps {
                max-width: none;

                .ant-steps-item-container {
                    .ant-steps-item-icon {
                        width: 25px;
                        height: 25px;

                        .ant-steps-icon {
                            top: -3px;
                        }

                        .ant-icon svg {
                            width: 2px !important;
                            height: 2px !important;
                        }
                    }

                    .ant-steps-item-title {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .access-container .new-account-form .ant-form {
        max-width: 375px;
    }

    .access-container .steps-register {
        display: none;
    }
}

// @media screen and (max-width: 1200px) {

//     .slick-slide {
//         width: 100% !important;
//         max-width: 400px;
//     }

//     .login-logo {
//         display: none !important;
//     }

//     .login-form {
//         width: 100% !important;
//         max-width: 600px;
//         margin: 0 auto;
//     }
// }
