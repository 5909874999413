.orientations-section {

  .text-highlight {
    color: #1d96d7;
  }

  .clicable {
    cursor: pointer;
  }

  .copy-icon {
    .anticon {
      color: #1d96d7;
    }
  }

  ul, ol {
      padding-left: 15px !important;

      li {
          margin: 0 !important;
          padding: 0 !important;
          margin-bottom: 10px !important;
          
          &:last-child {
              margin-bottom: 0;
          }
      }
  }
}