.modal-step-name {
    font-size: 16px;
    margin-bottom: 5px;
}

.disclaimer {
    background-color: #FEF2C6;
    display: flex;
    flex-direction: row;
    padding: 5px;
    font-size: 12px;
    margin-bottom: 25px;
}
.disclaimer-bar {
    height: auto;
    background-color: #E0AF00;
    width: 5px;
    top: 5px;
    bottom: 5px;
    margin-right: 10px;
}

.disclaimer-text {
    font-size: 14px;
}