@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.row-counters {
    padding-bottom: 24px;

    > .title {
        font-size: 18px !important;
        margin-bottom: 5px;
    }

    // mobile
    @media (max-width: $md-screen) {
        > .title {
            font-size: 16px !important;
        }
    }
}