// blue
$blue-0: #009BED;
$blue-1: #35A8E5;
$blue-3: #007AFF;


// gray
$gray-0: #EDEDED;
$gray-1: #F7F7F7;
$gray-2: #AAAFB5;
$gray-3: #6F6F6F;
$gray-4: #666666;
$gray-5: #F2F2F2;
$gray-6: #EAEAEA;
$gray-7: #9F9F9F;




// black
$black-0: #141414;
$black-1: #001327;
$black-2: #393939;


//red
$red-0: #FF4646;


//white
$white-0: #FFFFFF;

//green
$green-0: #00BC88;


//yellow
$yellow-0: #f7b205;
