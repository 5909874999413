@use "/src/common/styles/colors.scss" as colors;
@use "/src/common/styles/global.scss";

.select-file-box {
margin-bottom: 25px;
  .upload-files-title {
    font-size: 13px;
    font-weight: bold;
    margin: 25px 0 15px;
  }
}
.file-upload-box {
  .ant-row.label {
    @extend .font-normal-12;
    color: var(--primary-blue);
    line-height: 28px;
  }

  .ant-row.icon {
    padding: 0px 30px;

    svg {
      width: 25px;
      height: 25px;

      path {
        fill: colors.$gray-2;
      }
      &:hover {
        path {
          fill: var(--primary-blue);
        }
      }
    }
  }
}

.ant-upload-list-item-name {
  color: var(--primary-blue);
}

.ant-upload-list-item-card-actions {
  .delete-icon {
    svg {
      width: 23px;
      height: 23px;

      path {
        fill: colors.$gray-2;
      }
    }
  }
}
