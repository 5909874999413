.select-search-type {
    width: 100%;
    border-radius: 4px;

    &.ant-select:not(.ant-select-customize-input) .ant-select-selector,
    &.ant-select .ant-select-selector {
        font-size: 14;
        // border-radius: 4px;
        align-items: center;
        justify-content: center;
        height: 50px !important;
        box-shadow: none !important;
        border-radius: 0;
        border: 1px solid #DDD;
        background: #ffffff;

        .ant-select-selection-placeholder {
            font-size: 14;
            color: rgb(180, 180, 180);
        }
    }

    // &.select-error .ant-select-selector {
    //     border: 1px solid #CCC !important;
    // }

}

    
// .ant-select-focused {
//     border: 1px solid #DDD !important;
// }

.ant-select-item-group {
    color: #000 !important;
    font-weight: bold !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    color: #FFF !important;
    background-color: var(--primary) !important;
}