.monitoring-page {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  min-height: 100vh;

  th {
    background-color: #e8e8e8 !important;
    // border-radius: none !important;
    border-bottom: none !important;
    text-align: center !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: black !important;

    &::before {
      width: 1px !important;
      height: 100% !important;
      background-color: #d9d9d9 !important;
    }
  }

  // .ant-table-tbody > tr:not(:last-child)::after {
  //   content: "";
  //   display: block;
  //   height: 10px; /* Ajuste este valor para controlar o espaçamento */
  // }

  .ant-table-thead > tr > th:first-child {
    border-top-left-radius: 0 !important;
  }

  .ant-table-thead > tr > th:last-child {
    border-top-right-radius: 0 !important;
  }

  .square-big-button {
    width: 100px;
    height: 110px;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    align-items: center;
    border: none;
    background-color: #007aff;
    cursor: pointer;
    color: white;
    font-size: 14px;

    svg {
      margin-bottom: 13px;
    }
  }


  .square-big-button-zap {
    width: 100px;
    height: 110px;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    align-items: center;
    border: none;
    background-color: white;
    border: 1px solid #007aff;
    cursor: pointer;
    color: #007aff;
    font-size: 14px;

    svg {
      margin-bottom: 13px;
      fill: #007aff;
    }
  }

  .monitoring-content {
    padding: 90px 25px;
    max-width: 1300px;
    min-width: 80%;
  }

  .buscas-box {
    margin-top: 35px;
  }

  .icon-view:hover {
    color: var(--primary);
    cursor: pointer;
  }

  .info-target {
    padding-top: 10px;
    font-size: 12px !important;
    color: rgb(88, 88, 88) !important;

    .hide-on-full-screen {
      @media screen and (min-width: 768px) {
        display: none;
      }
    }
  }

  .row-table-services {
    .ant-table-wrapper {
      // width: 100%;
    }

    .table-services {
      td {
        background-color: hsl(205, 100%, 96%) !important;
      }
    }
  }

  .cart-item-price {
    font-size: 10px;
    position: relative;
    top: -3px;
    padding-left: 10px;
  }

  .welcome-title {
    margin-bottom: 25px;
    font-size: 24px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }
  }

  .box-totalizer {
    padding: 10px;
    height: 80px;
    width: 150px;
    background-color: #dddddd;
    border-radius: 7px;

    div:first-child {
      margin-bottom: 20px;
      font-size: 12px;
    }

    div:last-child {
      text-align: right;
      font-size: 25px;
    }
  }
}
