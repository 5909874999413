@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.docs-info {
    // padding: 25px;
    // margin-right: 50px;

    span {
        color:  colors.$gray-4;
        font-size: 16px;
        font-weight: bold;
        margin-right: 10px;
    }

    div {
        font-size: 18px;
        font-weight: normal;
    }
}