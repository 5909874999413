@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

$progress: calc(50% - 30px); // will be changed by useEffect
$move-top-elements: 10px;

.score-counter-bar-container {
    --bar-width: 50%;
    --bar-height: 50px;
    width: 100%;
    display: flex;
    justify-content: center;

    @media (max-width: $md-screen) {
        --bar-height: 30px;
        --bar-width: 100%;
    }

    .score-counter-bar {
        margin: 50px 0;
        background-image: linear-gradient(
            90deg,
            #ff0a02,
            #ff9700,
            #ffd300,
            #4b6fff,
            #15d100
        );
        width: var(--bar-width);
        height: var(--bar-height);
        border-radius: 30px;
        position: relative;
        z-index: 10;

        > div:first-child {
            width: 60px;
            text-align: center;
            margin-left: $progress;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            position: relative;
            top: $move-top-elements;
        }

        .bar-point {
            width: 15px;
            height: 15px;
            position: relative;
            top: -30px;
            z-index: 1000;
        }

        // .score-value {
        //     font-size: 40px;
        //     font-weight: 600;
        //     height: $score-value-height;
        //     color: colors.$gray-2;
        //     -webkit-text-stroke: 1px colors.$gray-4;

        //     @media (max-width: $md-screen) {
        //     font-size: 30px;
        //     }
        // }

        // .score-point {
        //     width: 8px;
        //     height: calc(var(--bar-height) + $score-point-height-out);
        //     background-color: colors.$white-0;
        //     border: 1px solid colors.$gray-3;
        // }

        // .score-scale {
        //     @extend .font-normal-14-600;
        //     width: 100%;
        //     text-align: center;
        //     padding: 15px 0;
        //     display: flex;
        //     justify-content: space-between;
        //     position: relative;
        //     top: $move-top-elements;
        // }
    }
}