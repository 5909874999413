label {
    text-transform: uppercase;
    font-size: 12px;

    &::before {
        content: "" !important;
    }

    &::after {
        content: ":" !important;
        visibility:visible !important;
        font-size: 12px !important;
    }
}
.simple-input {
    width: 100%;
    height: 50px;
    font-size: 14px;
    text-indent: 10px;
    font-weight: normal;
    box-sizing: border-box;
    border-radius: 0px;
    border: 1px solid #DDD;
    background: #FFF;
    
    &:hover {
        border: 1px solid #DDD;
    }

    &::placeholder {
        font-size: 14px;
        color: rgba(137, 135, 135, 0.80);
    }

    &:disabled {
        color: rgb(114, 114, 114);
        background-color: #DEDEDE;
        cursor: not-allowed;
    }

    &.with-preffix {
        padding-left: 22px;
    }

    &.with-suffix {
        padding-right: 22px;
    }

    &:focus {
        box-shadow: none !important;
    }

    &:focus-visible {
        outline: none;
        border: 1px solid #CCC !important;
    }

    .ant-input-group-addon {
        padding: 0 !important;
        background-color: transparent !important;
        border: none;

        .ant-select-selector {
            background-color: transparent !important;
            // .ant-select-selection-item {
            //     border-top: 1px solid #d9d9d9 !important;
            // }
        }
    }

    // Corrigir input do InputPhone
    .ant-input-wrapper {
        padding-right: 5px;

        .ant-input-affix-wrapper {
            border-radius: 0px;
            position: relative;
        }
    }
}

.ant-form-item-explain {
    font-size: 12px;
}

@media screen and (min-width: 768px) {
    .input-container {
        position: relative;

        &.input-container-password input {
            padding-right: 50px;
        }

        .input-label {
            padding-right: 40px;
        }

        .icon {
            font-size: 16px;
        }

        .simple-input {
            height: 40px;
            font-size: 16px;

            &::placeholder {
                font-size: 16px;
            }

            &.with-preffix {
                padding-left: 22px;
            }

            &.with-suffix {
                padding-right: 22px;
            }
        }
    }

    .ant-form-item-explain {
        font-size: 12px;
    }
}

@media screen and (min-width: 1024px) {
    .input-container {
        .simple-input {
            height: 50px;
            font-size: 18px;
        }
    }
}