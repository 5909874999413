@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.report-status-page {
  width: 100%;
  padding-top: 80px;

  .logo {
    position: static;
    width: 200px;
    height: auto;
    margin-left: 30px;
  }

  .status-icon svg {
    width: 25px;
    height: auto;
  }

  .status-idle svg,
  .status-loading svg {
    color: rgb(0, 0, 214);
  }
  .status-error svg {
    color: rgb(194, 75, 75);
  }
  .status-completed svg {
    color: rgb(0, 190, 0);
  }

  .ant-row.row-file-info {
    padding: 16px 16px !important;
    
    &:not(:last-child) {
      border-bottom: solid 1px rgb(220, 220, 220);
    }

    // .ant-col:first-child {
    //   min-width: 250px;
    // }

    // .ant-col:nth-child(2) {
    //   min-width: 50px;
    // }

    .file-type, .file-created-at {
      font-size: 8px;
      font-weight: bold;
      color: rgb(131, 131, 131);
      text-transform: uppercase;
    }

    .file-created-at {
      font-weight: normal;
    }

    .filename {
      font-size: 10px;
      font-weight: normal;
      color: black;
      text-transform: uppercase;
    }

    .file-error-msg {
      color: colors.$red-0;
    }

    .status-icon {
      margin-right: 10px;
    }

    .infos-file {}
  }


  .msg-info-service {
    color: colors.$gray-4;
  }

  .report-title,
  .report-subtitle {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin: 20px 0;
    text-transform: uppercase;
  }

  .report-subtitle {
    font-size: 16px;
  }

  .report-case-name {
    padding: 0 25px;
    margin: 30px 0;
    text-transform: uppercase;
    
    span {
      @extend .font-title-16-600;
    }
  }

  .report-header {
    padding: 0 25px;
    width: 100%;
    text-align: left;
    margin: 40px 0 !important;
  }

  .report-status-info {
    justify-content: center;

    .ant-col:first-child {
      .inquest-icon svg {
        width: 35px;
        height: 35px;
      }
    }

    .ant-col:nth-child(2) {
      @extend .font-normal-14;
      color: colors.$black-2;
    }

    @media (max-width: $md-screen) {
      justify-content: flex-start;

      .ant-col:nth-child(2) p {
        max-width: 65%;
      }
    }
  }

  .report-logs {
    .ant-card-body {
      max-height: 350px;
      overflow-y: auto;
    }
  }

  .report-title {
    @extend .font-title-20-600;
    font-size: 25px;
    width: 100%;
    margin: 0 0 10px 0;
    color: colors.$blue-0 !important;

    label {
      @extend .font-normal-14-600;
      color: colors.$gray-4;
      display: block;
      margin-bottom: 10px;
    }
  }

  .section-pesquisados {
    margin: 40px 0;
    padding: 25px;
  }

  .section-report-files {
    padding: 25px;
  }

  .report-info,
  .context-info {
    div {
      margin: 10px 0;

      label {
        @extend .font-normal-14-600;
        color: colors.$gray-4;
        margin-right: 5px;
        display: inline;
      }

      span {
        @extend .font-normal-14;
        color: colors.$black-2;
        display: inline;
      }

      li {
        @extend .font-normal-14;
        color: colors.$black-2;
        margin-top: 8px;
      }
    }

    .label-show-context {
      cursor: pointer;

      &[data-has-context="true"]:hover {
        * {
          color: colors.$blue-0 !important;
        }
      }

      .inquest-icon {
        margin-left: 10px;
      }

      &[data-has-context="false"] {
        cursor: auto;

        .inquest-icon {
          display: none;
        }
      }
    }
  }

  .report-list-status {
    
    .service-info {
      width: 100%;
      padding-bottom: 10px;
      font-size: 20px;
    }

    .service-data {
      margin: 10px 0;
      padding: 15px;
      background-color: colors.$gray-0;
      border-radius: 10px;
      height: auto;
      max-width: 600px;
      
      > div {
        margin: 8px 0;

        label {
            @extend .font-normal-14-600;
            color: colors.$gray-4;
            margin-right: 5px;
            display: inline;
        }

        span {
            @extend .font-normal-14;
            color: colors.$black-2;
            display: inline;
        }
      }
  }
  }
}
