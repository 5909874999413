.ant-input-number {
    width: 100% !important;
    height: 50px;
    font-size: 14px;
    text-indent: 10px;
    font-weight: normal;
    box-sizing: border-box;
    border-radius: 0px;
    border: 1px solid #DDD;
    background: #ffffff;

    &::placeholder {
        font-size: 16px;
        color: rgb(180, 180, 180);
    }

    &.with-preffix {
        padding-left: 22px;
    }

    &.with-suffix {
        padding-right: 22px;
    }

    &:focus {
        box-shadow: none !important;
    }

    &:focus-visible {
        outline: none;
        border: 1px solid #CCC !important;
    }

    .ant-input-number-input {
        width: 100%;
        height: 50px;
        padding: 0 !important;
    }
}