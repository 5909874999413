
@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

button.outlined-button,
.outlined-button.ant-tooltip-disabled-compatible-wrapper button {
    @extend .font-normal-12;
    font-size: 10px;
    font-weight: 600;
    color: colors.$black-0;
    background-color: transparent;
    border-radius: 10px;
    min-width: 180px;
    min-height: 28px;
    padding-top: 8px;
    // box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    border: 1px solid colors.$black-0;
    text-transform: uppercase;

    &:hover {
        > .anticon, .ant-btn-loading-icon {
            svg {
                color: colors.$blue-0 !important;
            }
        }
    }

    span:last-child {
        position: relative;
        top: -1px;
    }

    > .anticon, .ant-btn-loading-icon {
        position: relative;
        left: -3px;
        top: 2px;

        svg {
            color: colors.$black-0;
            height: 1.6em;
            width: 1.6em;
        }
        
    }
}

.graph-page {
    width: 100%;
    height: 100%;
    background-color: colors.$gray-0;

    .graph-msg {
        @extend .font-normal-18-600;
        padding: 250px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        color: colors.$gray-4;

        > div {
            text-align: center;
            max-width: 350px;

            .inquest-icon {
                margin-bottom: 30px;
                display: block;

                svg {
                    width: 28px;
                    height: auto;
                    position: relative;
                    top: 5px;
                    color: colors.$yellow-0;
                }
            }
        }
    }
}


.graph-nav {
    width: 100%;
    height: 60px;
    background-color: transparent;
    display: flex;
    align-items: center;
    padding-left: 30px;

    button {
        position: fixed;
        z-index: 1000;
        left: 15px;

        @media (max-width: $md-screen) {
            min-width: 0 !important;
            width: auto !important;


            span:first-child {
                position: relative;
                left: -1px;
            }

            span:last-child {
                display: none;
            }
        }
    }

    .logo-graph {
        @extend .font-title-16-600;
        font-size: 16px;
        text-transform: uppercase;
        position: fixed;
        left: calc(50% - (247px / 2));
        width: 265px;        
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $md-screen) {
            left: calc(55% - (265px / 2));
        }
        
        span:last-child {
            font-size: 21px;
            position: relative;
            letter-spacing: 2px !important;
        }

        .logo {
            height: 30px;
            width: auto;
        }
    }

    
}

.graph-loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}