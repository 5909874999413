.login-forgot-container {
    width: 100%;
    height: 100vh;
    display: flex;

    .login-new-account {
        margin-top: 125px;
        text-align: center;

        a {
            // padding-left: 5px;
            font-weight: 600;
        }
    }
    
    .auth-page-name {
        text-transform: uppercase;
        width: 100%;
        text-align: center;
        font-size: 32px;
        margin-top: 80px;
        margin-bottom: 10px;
    }
    
    .register-left {
        height: 100%;
        width: 50vw;
        background-color: #007AFF;
        padding-bottom: 60px;
    }

    .register-rigth {
        display: flex;
        width: 50vw;
        // justify-content: center;
        // align-self: center;
        flex-direction: column;
        padding-bottom: 60px;
        align-items: center;
    }

    .login-forgot-form {
        // padding: 20% 20px;
        margin-top: 115px;
        height: 100%;
        max-width: 495px;
        
        .login-forgot-form-logo {
            width: 100%;
            text-align: center;

            img {
                width: 70%;
                padding-bottom: 2em;
            }
        }

        .main-text {
            padding-top: 30px;
            padding-bottom: 30px;
            text-align: left;

            .form-title {
                font-size: 1.1em;
                text-align: center;
            }

            .form-subtitle {
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                margin-bottom: 80px;
            }
        }
    }

    .ant-btn-default:disabled {
        background-color: var(--primary);
        color: #fff;
    }
}


@media screen and ( max-width: 768px) {

    .login-forgot-container {
        // display: none;
        padding-left: 0;
        // max-width: 700px;
        margin: 0 auto;

        .login-forgot-form-logo {
            width: 100% !important;

            img {
                width: 50% !important;
                padding-bottom: none;
            }
        }

        // .login-forgot-form-logo {
        //     width: 100%;
        //     text-align: center;

        //     img {
        //         width: 70%;
        //         padding-bottom: 2em;
        //     }
        // }
        

        .main-text {
            // padding-top: 10px;
            // padding-bottom: 10px;
            // margin-top: 0px;
            .auth-page-name {
                margin-top: 10px;
                font-size: 25px;
            }
        }

        .form-title {
            font-size: 2em !important;
            text-align: center;
        }

        .register-rigth {
            width: 100%;
            padding-left: 10px;
            padding-right: 10px;
        }

        .register-left {
            display: none;
        }
    
        .form-subtitle {
            font-weight: normal;
            font-size: 16px;
        }

        .login-forgot-form-button {
            margin-top: 30px;
        }
    }
}