
.container-menu {
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    z-index: 99999;
    position: fixed;
    transition: right 0.3s ease-in-out;
    -moz-transition: right 0.3s ease-in-out;
    background-color: rgb(0, 0, 0, .3);
    -webkit-transition: right 0.3s ease-in-out;

    &.menu-open {
        right: 0;
        transition: right 0.3s ease-in-out;
        -moz-transition: right 0.3s ease-in-out;
        -webkit-transition: right 0.3s ease-in-out;
    }

    .menu {
        top: 0;
        right: 0;
        width: 100%;
        height: auto;
        z-index: 9999;
        position: absolute;
        background-color: var(--secondary);

        .options {
            display: flex;
            text-align: right;
            padding: 100px 0;
            flex-direction: column;

            .option {
                width: 100%;
                border: none;
                outline: none;
                color: #FFF;
                font-size: 16px;
                cursor: pointer;
                font-weight: 600;
                transition: 0.2s;
                line-height: 18px;
                padding: 15px 22px;
                margin-bottom: 10px;
                text-decoration: none;
                box-sizing: border-box;
                background-color: transparent;

                &:last-child {
                    margin: 0px;
                }

                &:hover {
                    transition: 0.2s;
                    background-color: rgba(255, 255, 255, 0.194);
                }

                &.href-desk {
                    display: none;
                }
            }
        }
    }
}

.menu-btn {
    top: 0;
    right: 1px;
    width: 70px;
    height: 50px;
    display: flex;
    outline: none;
    cursor: pointer;
    position: fixed;
    z-index: 999999999;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;

    .menu-btn-burger {
        width: 28px;
        height: 2px;
        outline: none;
        border-radius: 5px;
        background-color: #FFF;
        transition: all 0.4s ease-in-out;

        &::before,
        &::after {
            content: "";
            width: 28px;
            height: 2px;
            position: absolute;
            border-radius: 5px;
            background-color: #FFF;
            transition: all 0.4s ease-in-out;
        }

        &::before {
            transform: translate(0, -7px);
        }

        &::after {
            transform: translate(0, 7px);
        }
    }
}

.menu-btn.open {
    .menu-btn-burger {
        transform-origin: left;
        transform: translateX(-12px);
        background-color: transparent;
    }

    .menu-btn-burger::before {
        transform: rotate(45deg) translate(8px, -8px);
    }

    .menu-btn-burger::after {
        transform: rotate(-45deg) translate(8px, 8px);
    }
}


@media only screen and  (min-width: 768px) {
    .menu-btn {
        display: none;
    }
}