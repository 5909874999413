.input-cpf-cnpj-list {
    .form-list-title {
        // font-size: 13px;
        // margin-top: 5px;
        // text-transform: uppercase;
    }

    .fields-edit {
        // padding: 10px 0 0 0;
        &:not(:last-child) {
            margin-bottom: 16px !important;
        }

        &.border {
            border-top: 1px dashed rgba(0, 0, 0, 0.15);
        }

        .ant-form-item {
            // width: 100%;

            .simple-input {
                cursor: default !important;
                margin: 0 0 0 0 !important;
            }
        }

        .button-add {
            height: 30px !important;
        }

        .button-remove {
            svg {
                width: 20px;
                height: 20px;
                top: 30px;
                position: relative;
                color: red;
            }

            @media only screen and (max-width: 768px) {
                top: 0;

                svg {
                    position: inherit;
                }
            }
        }
    }

    button {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: 0.6;
        }
    }

    .list-error.ant-form-item-explain {
        margin-top: 32px;
        position: relative !important;
        color: red !important;
    }

    .price {
        b {
            color: var(--secondary);
        }
    }
}
