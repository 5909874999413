.ant-form-item-has-error .rounded-input,
.ant-form-item-has-error .rounded-input:hover,
.ant-form-item-has-error .rounded-input:focus {
    // border-color: colors.$red-0 !important;
}

.ant-input-affix-wrapper.antd-country-phone-input {
    padding: 0;
    width: 100%;
    height: 50px;
    // text 
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    border: none !important;
    box-shadow: none !important;

    .ant-input-prefix {
        padding: 6px 16px;
        border-radius: 4px;
        // color: colors.$black-0;
        // border: 1px solid colors.$gray-0;

        .ant-select-selector {
            padding: 0;
            cursor: pointer;

            .ant-select-selection-search {
                input {
                    border: none !important;
                }
            }

            .ant-select-selection-item {
                padding: 0;
                font-size: 18px;
            }
        }

        // remove arrow and search icon
        .ant-select-arrow {
            display: none;
        }
    }

    .ant-input {
        padding: 16px;
        font-size: 16px;
        border-radius: 4px;
        // color: colors.$black-0;
        // border: 1px solid colors.$gray-0;

        &::placeholder {
            font-size: 18px;
            // color: colors.$gray-0;
        }
    }
}
