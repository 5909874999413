@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.ant-tabs-nav {
    margin: 0px !important;
}

.doc-view-page {
        
    .loading-text {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        color: blue;
    }
}