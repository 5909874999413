.container-header {
    top: 0;
    width: 100%;
    height: 40px;
    display: flex;
    z-index: 99999;
    padding: 0 14px;
    position: fixed;
    box-sizing: border-box;
    justify-content: center;
    background-color: #007AFF;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);

    header {
        width: 100%;
        max-width: 80%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
            width: 100px;
            outline: none;
            cursor: pointer;
        }

        .header-options {
            display: none;
        }

        .user-profile-icon {
            padding: 5px;
        }
    }

    .container-menu .menu {
        // background-image: linear-gradient(to left, var(--primary), var(--secondary));
        background-color: #007AFF;
        min-height: 100vh;
        .options {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            button {
                width: 250px;
                border: 1px solid white;
                border-radius: 8px;
            }
        }
    }
    
}

@media only screen and (min-width: 768px) {
    .container-header {
        height: 50px;
        // padding: 0 30px;

        header {
            .logo {
                width: 145px;
            }

            .header-options {
                height: 75px;
                display: flex;
                align-items: center;
                box-sizing: border-box;

                .header-option {
                    height: 40px;
                    border: none;
                    display: flex;
                    outline: none;
                    color: #FFF;
                    font-size: 16px;
                    cursor: pointer;
                    padding: 4px 8px;
                    line-height: 19px;
                    margin-left: 16px;
                    border-radius: 2px;
                    border-radius: 4px;
                    align-items: center;
                    text-decoration: none;
                    box-sizing: border-box;
                    border: 1px solid #FFF;
                    background-color: transparent;
                    transition: all .3s ease-in-out;

                    &:hover {
                        opacity: .8;
                        // color: var(--secondary);
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1024px) {
    .container-header {
        height: 55px;
        //padding: 0 81px;
        position: fixed;
        box-shadow: none;
        
        header {
            .logo {
                width: 130px;
            }

            .header-options {
                height: 70px;
                
                .header-option {
                    height: 35px;
                    min-width: 50px;
                    padding: 0 12px;

                    &:hover {
                        background-color: white;
                        color: black;
                    }
                }
            }
        }
    }
}


@media only screen and (min-width: 1440px) {
    .container-header {
        // padding: 0 105px;
        header {
            // max-width: 1440px;
        }
    }
}