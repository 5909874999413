.users-client-page {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  min-height: 100vh;
  max-width: 100vw;

  .users-container {
    padding: 90px 25px;
    max-width: 1300px;
    min-width: 80%;
    
    .users-box {
      margin-top: 30px;
    }

    .info-user {
      padding-top: 10px;
      font-size: 12px !important;
      color: rgb(88, 88, 88) !important;
  
      @media screen and (min-width: 975px) {
        display: none;
      }
    }
  }
}