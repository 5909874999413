@use "./colors" as colors;

$sm-screen: 600px;
$md-screen: 768px;
$lg-screen: 992px;
$xl-screen: 1200px;

.font-family-1 { // Titulos e subtitulos
    // font-family: 'Bebas Neue', cursive !important;
    // letter-spacing: 2px !important;
    font-family:  'Roboto', sans-serif;
}

.font-family-2 { // textos em geral
    font-family: "Trebuchet MS", "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Tahoma, sans-serif !important;
}

.font-normal-12 {
    @extend .font-family-1;
    font-size: 12px;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}

.font-normal-12-600 {
    @extend .font-family-1;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}

.font-normal-14-600 {
    @extend .font-family-1;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}

.font-normal-16-600 {
    @extend .font-family-1;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}


.font-normal-14 {
    @extend .font-family-1;
    font-size: 14px;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}

.font-normal-18-600 {
    @extend .font-family-1;
    font-size: 18px;
    font-weight: 600;
    
    @media (max-width: $md-screen) {
        font-size: 15px;
    }
}

.font-normal-20-600 {
    @extend .font-family-1;
    font-size: 20px;
    font-weight: 600;
    
    @media (max-width: $md-screen) {
        font-size: 16px;
    }
}

.font-title-12-600 {
    @extend .font-family-2;
    font-size: 12px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 11px;
    }
}

.font-title-16 {
    @extend .font-family-2;
    font-size: 16px;

    @media (max-width: $md-screen) {
        font-size: 12px;
    }
}

.font-title-16-600 {
    @extend .font-family-2;
    font-size: 16px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 12px;
    }
}

.font-title-14-600 {
    @extend .font-family-2;
    font-size: 14px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 12px;
    }
}

.font-title-18-600 {
    @extend .font-family-2;
    font-size: 18px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 15px;
    }
}

.font-title-20-600 {
    @extend .font-family-2;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: $md-screen) {
        font-size: 16px;
    }
}

html {
    scroll-behavior: smooth;
}

// Altera o layout das modal expandir em mobile
@media only screen and (max-width: 768px) {
    .ant-modal-root {
        
        .ant-modal-wrap {
            background-color: white !important;
        }

        .ant-modal {
            margin: 0 !important;
            width: 100vw !important;
            max-width: none !important;
            top: 40px !important;
            
            .ant-modal-content {
                border-radius: 0px;
                height: calc(100vh - 80px);
                box-shadow: none !important;

                .ant-modal-body {
                    min-height: calc(100vh - 260px);;
                }
                
                .ant-modal-footer {
                    padding-bottom: 25px !important;
                }
            }
        }
    }
}

// Altera o layout das opções do Select em mobile
@media only screen and (max-width: 768px) {
    .ant-select-item-option {
        border-top: solid 1px #EAEAEA;
        border-radius: 0px !important;
    }

    .ant-select-item-option-content {
        white-space: break-spaces !important;
        word-break: break-word !important;
        padding: 15px !important;
        font-size: 12px;
    }

    .ant-select-item-group + .ant-select-item-option {
        border-top: none;
    }
}

body {
    @extend .font-normal-14;
    
    margin: 0;
    min-height: 100vh;

    // colors 
    --primary: #009BED;
    --secondary: #FF4646;
    --text: #393939;

    background-color: #EEEEEE;

    .page {
        margin-top: 60px;
        overflow-x: hidden;
        padding: 28px 14px 56px;
    }

    a {
        color: #007AFF;
    }

    @media only screen and (min-width: 768px) {
        .page {
            margin-top: 75px;
            padding: 45px 30px;
        }
    }

    @media only screen and (min-width: 1024px) {
        .page {
            margin-top: 0;
            padding: 70px 105px;
        }
    }

    @media only screen and (min-width: 1440px) {
        .page {
            max-width: 1440px;
            margin: 0 auto 0;
        }
    }

    .ant-col.ant-form-item-label {
        padding: 0;

        label {
            font-size: 12px;
        }
    }

    .ant-form-item {
        margin-bottom: 8px !important;
    }

    .btn-blue {
        background-color: var(--primary);
        color: #fff !important;

        &:hover {
            opacity: 0.6;
            color: #fff !important;
        }
    }
}

#root {
    height: 100%;
}

.app {
    height: 100%;
}

// custom scrollbar
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: colors.$gray-7;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: colors.$gray-2;
}

.ant-message {
    z-index: 9999999999999 !important;

    .ant-message-notice-content {
        width: 90%;
        height: 40vh;
        display: flex;
        margin: 0 auto;
        max-width: 320px;
        max-height: 100px;
        align-items: center;
        justify-content: flex-start;

        .ant-message-custom-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .anticon {
                svg {
                    width: 25px !important;
                    height: 25px !important;
                }
            }

            span:nth-child(1) {
                font-size: 20px;
            }
        }
    }
}

.ant-form-item-has-error {
    input {
        border: 1px solid red;
    }

    input:focus-visible {
        border: 1px solid red !important;
    }
}