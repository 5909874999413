@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.score-container {

    .title-name {
        font-size: 18px;
    }

    .title-score {
        font-size: 36px;
    }

    hr {
        margin: 48px 0 !important;
    }
    
    &:last-child {
        margin-bottom: 0;
        hr {
            display: none;
        }
    }

    .progress-bar-container {
        margin: 25px 0;
        .progress-bar {
            max-width: 400px;
        }
    }

    // mobile
    @media (max-width: $md-screen) {
        .title-name {
            font-size: 16px;
        }
    
        .title-score {
            font-size: 24px;
        }
    }
}