@use "/src/common/styles/colors" as colors;
@import "/src/common/styles/global.scss";

.blue-square-button {
    background: #007AFF;
    color: white;
    text-transform: uppercase;
    padding: 14px 25px;
    font-size: 14px;
    border: none;
    cursor: pointer;

    &:disabled {
        background-color: #DEDEDE;
        cursor: not-allowed;
        // color: black;
    }
}
.blue-square-small-button {
    background: #007AFF;
    color: white;
    text-transform: uppercase;
    padding: 7px 25px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    
    &:disabled {
        background-color: #DEDEDE;
        cursor: not-allowed;
        // color: black;
    }
}

.blue-square-outlined-button {
    color: #007AFF;
    background-color: white;
    text-transform: uppercase;
    border: 1px solid #007AFF;
    padding: 14px 25px;
    font-size: 14px;
    // border: none;
    cursor: pointer;
}

.blue-square-outlined-small-button {
    color: #007AFF;
    background-color: white;
    text-transform: uppercase;
    border: 1px solid #007AFF;
    padding: 5px 12px;
    font-size: 14px;
    // border: none;
    cursor: pointer;
}

.simple-button {
    border: none;
    height: 40px;
    color: #FFF;
    cursor: pointer;
    font-size: 14px;
    min-width: 140px;
    border-radius: 20px;
    transition: all 0.2s ease-in-out;
    background-color: var(--secondary);

    &:active {
        opacity: .6;
    }

    &:disabled {
        opacity: .6;
        cursor: not-allowed;
    }

    .ant-spin {
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-spin-dot {
            font-size: 14px;

            .ant-spin-dot-item {
                width: 6px;
                height: 6px;
                background-color: #FFF !important;
                // background-color: var(--primary) !important;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .simple-button {
        height: 45px;
        font-size: 16px;
        min-width: 210px;
        border-radius: 30px;
    }
}

@media screen and (min-width: 1024px) {
    .simple-button {
        height: 40px;
        color: #FFF;
        font-size: 14px;
        min-width: 240px;
        border-radius: 20px;

        &:hover {
            opacity: .6;
        }
    }
}




button.rounded-button,
.rounded-button.ant-tooltip-disabled-compatible-wrapper button {
    @extend .font-normal-12;
    font-weight: 600;
    border-radius: 50px;
    min-width: 190px;
    height: 45px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 6px;
    text-transform: uppercase;

    &.square-button {
        border-radius: 0;
    }

    span:last-child {
        position: relative;
        top: -1px;
        // min-width: 140px;
    }

    > .anticon, .ant-btn-loading-icon {
        margin-left: 3px;
        position: relative;
        left: -3px;
        top: 2px;

        svg {
            height: 1.6em;
            width: 1.6em;
        }
    }

    &.btn-blue {
      background-color: var(--primary);
      color: #fff !important;

      &:hover {
        opacity: 0.6;
        color: #fff !important;
      }
    }
}


button.plain-button, 
.plain-button.ant-tooltip-disabled-compatible-wrapper button {
    @extend .font-normal-12;
    font-size: 12px !important;
    font-weight: 600;
    border-radius: 0 !important;
    height: 45px;
    text-transform: uppercase;

    &.shadow-primary {
        box-shadow: rgba(77, 77, 77, 0.24) 0px 0px 6px;
    }

    &.color-primary {
        background-color: #007AFF;
        color: white;

        &:hover {
            opacity: 0.7;
            border-color: white !important;
            color: white !important;
        }
    }
    
    &.color-secondary {
        background-color: white;
        color: #007AFF;
        border-color: #007AFF !important;

        &:hover {
            opacity: 0.7;
            border-color: white !important;
            background-color: #007AFF !important;
            color: white !important;
        }
    }

    &.fmt-square {
        width: 85px;
        height: 85px;
        align-items: center;

        span {
            display: block;
            width: 100%;
            text-align: center;
            
            &:not(.ant-btn-icon) {
                font-size: 10px;
                display: inline-block;
                word-wrap: break-word;
                white-space: pre-wrap;
            }
        }
        

        .ant-btn-icon {
            margin-bottom: 10px;

            svg {
                width: 35px;
                height: 35px;
            }
        }
    }

    &.fmt-rounded {
        border-radius: 20px;
    }

    &.square-button {
        border-radius: 0;
    }


    &:disabled {
        background-color: #DEDEDE !important;
        cursor: not-allowed;
    }

    span:last-child {
        position: relative;
        top: -1px;
        // min-width: 140px;
    }

    > .anticon, .ant-btn-loading-icon {
        margin-left: 3px;
        position: relative;
        left: -3px;
        top: 2px;

        svg {
            height: 1.6em;
            width: 1.6em;
        }
    }

    &.btn-blue {
      background-color: var(--primary);
      color: #fff !important;

      &:hover {
        opacity: 0.6;
        color: #fff !important;
      }
    }
}


.request-file-btn {
    @extend .font-normal-12;
    color: colors.$blue-0;
}