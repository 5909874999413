.form-list {
    .form-list-title {
        font-size: 13px;
        font-weight: bold;
        margin: 25px 0 15px;
    }

    .fields-edit {
        display: flex;
        padding: 10px 0 16px;
        flex-direction: column;
        &.border {
            border-top: 1px dashed rgba(0, 0, 0, .15);
        }

        .ant-form-item {
            width: 100%;
            margin-bottom: 22px !important;
            .simple-input {
                cursor: default !important;
                margin: 0 0 0 0 !important;
            }
        }

        .button-remove {
            width: 90px;
            height: 30px;
            border: none;
            color: #FFF;
            grid-column: 2;
            cursor: pointer;
            font-weight: bold;
            border-radius: 5px;
            margin: 0 0 0 auto;
            background-color: var(--secondary);
        }
    }

    .button-add {
        height: 30px;
        width: 100px;
        border: none;
        color: #FFF;
        padding: 0 15px;
        border-radius: 4px;
        background-color: var(--primary);
    }

    button {
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            opacity: .6;
        }
    }

    .list-error.ant-form-item-explain {
        margin-top: 32px;
        position: relative !important;
        color: red !important;
    }
}